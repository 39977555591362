import React from 'react'

const TripData = (props) => {
  return (
    <div className='t-card'>
      <diV className="t-image">
        <img alt="image" src={props.image}/>
      </diV>
      <h4>{props.heading}</h4>
      <p>{props.text}</p>
    </div>
  )
}

export default TripData
