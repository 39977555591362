

const Menu = [
        {
            title:"Home",
            url:"/",
            cName:"nav-links",
            icon:"fa-solid fa-house"
        },
        {
            title:"About",
            url:"/about",
            cName:"nav-links",
            icon:"fa-solid fa-circle-info"
        },
        {
            title:"Service",
            url:"/Service",
            cName:"nav-links",
            icon:"fa-solid fa-globe"
        },
        {
            title:"Contact",
            url:"/contact",
            cName:"nav-links",
            icon:"fa-solid fa-user"
        },
        {
            title:"Sign-Up",
            url:"/signup",
            cName:"nav-links",
            icon:"fa fa-sign-in"
            
        }
      
    ]

export default Menu
