import React from 'react'
import img1 from"../asets/Baobab-Avenue.jpg"
import img2 from"../asets/nature1.jpg"
import img3 from"../asets/nature2.jpg"
import img4 from"../asets/nature3.jpg"
import "./Destination.css"
import DestinationData from './DestinationData'

const Destination = () => {
    
  return (
    <div className='destination'>
      <h1>Popular Destinations</h1>
      <p>Tours Gives You the Opportunity to see a lot, within a time frame</p>
   <DestinationData
    className="first-des"
      heading="The Great Barrier Reef, Australia"
      text="Grand Canyon National Park, often called one of the Seven Natural Wonders of the World, is on most travelers’ lists for a reason. Plan to hike some of the park’s most scenic loops—like Horseshoe Bend and the South Rim Trail—to get views of the rocky badlands of the Painted Desert, Navajo Nation, and even a waterfall or two."
      image1={img1}
      image2={img2}/>
         <DestinationData
         className="first-des-reverse"
      heading="Khajjiar, Himachal Pradesh"
      text="Located in the eastern district of Uttarakhand, Pithoragarh boasts of irresistible natural beauty, high altitude grasslands and historic charm. It is the starting point for treks to the holy shrines of Kailash and Mansarovar. And, the panoramic views of the mighty peaks of Nanda Devi, Appi of Nepal and Panchachuli are clearly visible from this place. Some of the tourist spots include the Pithoragarh Fort, Askot Sanctuary that is home to a wide range of flora and fauna, and Kafni Glacier Trek, among many others. And, visitors can also indulge in adventure activities, like hiking, skiing, canoeing, kayaking and fishing. This tiny place is an ideal place for those looking for a nature retreat."
      image1={img3}
      image2={img4}/>
    </div>
  )
}

export default Destination
