import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import Destination from '../component/Destination'
import Trip from '../component/Trip'
import Footer from '../component/Footer'
const Home = () => {
  return (
    <div>
         
         <Navbar/>
         <Hero
         cName="hero"
         image="image1.jpg"
        title="Your Journey Your Story"
        text="Choose Your Favourite Destination" 
        buttonText="Travel Plan"
        url="/"
        btnClass="show"/>
        <Destination/>
       <Trip/>
       <Footer/>
    </div>
  )
}

export default Home
