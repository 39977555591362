import React from 'react'
import './Navbar.css'
import Menu from './MenuItems'
import { useState } from 'react'
import { Link } from 'react-router-dom'
const Navbar = () => {
   const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };
  return (
  <nav className='NavbarItems'>
     <h1 className="navbar-logo">Trippy</h1>
     <div className='menu-icons' >
      <i className={clicked ?"fas fa-times":"fas fa-bars"} onClick={handleClick} ></i>
     </div>
     <ul className={clicked?"nav-menu active":"nav-menu"} onClick={handleClick}>
   {Menu.map((item,index)=>
   {
      return (
         <li key = {index}>
            <Link className =  {item.cName} to={item.url}>
               <i className={item.icon}></i>
               {item.title}
            </Link>
         </li>
      )
   })}

   </ul>
   </nav>
  ) 
}

export default Navbar

