import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import Img from '../asets/image3.jpg'
import Footer from '../component/Footer'
import ContactForm from '../component/ContactForm'
const Contact = () => {
  return (
    <div>
       <Navbar/>
         <Hero
         cName="hero-mid"
         image={Img}
        title="Contact"
        // text="Choose Your Favourite Destination" 
        // buttonText="Travel Plan"
        // url="/"
        btnClass="hide"/>
        <ContactForm/>
           <Footer/>
    </div>
  )
}

export default Contact
