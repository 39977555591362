import React from 'react'
import './Aboutus.css'
const Aboutus = () => {
  return (
    <div className='about-container'>
      <h1>Our History</h1>
      <p>Journey Junction was founded in [year] by a group of passionate adventurers who shared a common vision for creating a platform that revolutionizes the way people experience and connect with the world. What started as a humble idea soon blossomed into a full-fledged organization, driven by a deep-rooted love for travel, cultural immersion, and personal growth. Over the years, we have curated countless transformative journeys, connecting like-minded individuals with unique destinations and experiences.</p>
     
      <h1>Our Mission</h1>
      <p>Journey Junction was founded in [year] by a group of passionate adventurers who shared a common vision for creating a platform that revolutionizes the way people experience and connect with the world. What started as a humble idea soon blossomed into a full-fledged organization, driven by a deep-rooted love for travel, cultural immersion, and personal growth. Over the years, we have curated countless transformative journeys, connecting like-minded individuals with unique destinations and experiences.</p>

      <h1>Our Vission</h1>
      <p>Journey Junction was founded in [year] by a group of passionate adventurers who shared a common vision for creating a platform that revolutionizes the way people experience and connect with the world. What started as a humble idea soon blossomed into a full-fledged organization, driven by a deep-rooted love for travel, cultural immersion, and personal growth. Over the years, we have curated countless transformative journeys, connecting like-minded individuals with unique destinations and experiences.</p>

    </div>
  )
}

export default Aboutus
