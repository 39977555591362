import React from 'react'
import ContactForm from './ContactForm'
import './ContactFile.css'
const SignupForm = () => {
  return (
    <div className='from-container'>
    <h1>Sign UP </h1>
    <form>
         <h4>Email :</h4>
      <input placeholder='Email :'/>
      <h4>password :</h4>
      <input placeholder='Password should be of atleast 6 characters'/>
      <h4>Confirm Password :</h4>
      <input placeholder='Must be same as password'/>
      
      <button>Sign Up</button>
    </form>
  </div>
  )
}

export default SignupForm
