import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import Img from '../asets/pexels-trung-nguyen-1619303.jpg'
import Footer from '../component/Footer'
import Trip from '../component/Trip'

const Service = () => {
  return (
    <div>
        <Navbar/>
         <Hero
         cName="hero-mid"
         image={Img}
        title="Service"
        // text="Choose Your Favourite Destination" 
        // buttonText="Travel Plan"
        // url="/"
        btnClass="hide"/>
        <Trip/>
        <Footer/>
    </div>
  )
}

export default Service
