import React from 'react'
import img1 from '../asets/nature5.jpg'
import Hero from '../component/Hero'
import SignupForm from '../component/SignupForm'
import Navbar from '../component/Navbar'
import Footer from '../component/Footer'

const SignUp = () => {
  return (
    <div>
      <Navbar/>
     <Hero
         cName="hero-mid"
         image={img1}
        title="Sign UP"
        // text="Choose Your Favourite Destination" 
        // buttonText="Travel Plan"
        // url="/"
        btnClass="hide"/>
        <SignupForm/>
        <Footer/>
    </div>
  )
}

export default SignUp
