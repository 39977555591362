import React from 'react'
import './Trip.css'
import TripData from './TripData'
import trip1 from '../asets/nature4.jpg'
import trip2 from '../asets/nature1.jpg'
import trip3 from '../asets/image6.jpg'
const Trip = () => {
  return (
    <div className='trip'>
      <h1>Recent Trips</h1>
      <p>You can discover unique destination using Google Maps</p>
      <div className='tripcard'>
        <TripData 
        image ={trip1}
        heading="Trip in Nainital"
        text="Explore the nature trails of a city and experience secrets in its kitty in the beautiful city of Nainital. Enjoy the company of tall trees and beautiful landscapes with this nature walk. 1. The scenic green beauty of nature and hills will give nature-lovers a small dose of wilderness needed to refresh their spirit."
        />
         <TripData 
        image ={trip2}
        heading="Trip in Nainital"
        text="Explore the nature trails of a city and experience secrets in its kitty in the beautiful city of Nainital. Enjoy the company of tall trees and beautiful landscapes with this nature walk. 1. The scenic green beauty of nature and hills will give nature-lovers a small dose of wilderness needed to refresh their spirit."
        />
         <TripData 
        image ={trip3}
        heading="Trip in Nainital"
        text="Explore the nature trails of a city and experience secrets in its kitty in the beautiful city of Nainital. Enjoy the company of tall trees and beautiful landscapes with this nature walk. 1. The scenic green beauty of nature and hills will give nature-lovers a small dose of wilderness needed to refresh their spirit."
        />
      </div>
    </div>
  )
}

export default Trip
