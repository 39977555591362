import React from 'react'
import './App.css'
import {Route, Routes } from 'react-router-dom'
import About from './routes/About'
import Contact from './routes/Contact'
import Service from './routes/Service'
import Home from './routes/Home'
import SignUp from './routes/SignUp'

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/service" element={<Service/>}/>
        <Route path="/signup" element={<SignUp/>}/>
      
      </Routes>
    </div>
  )
}

export default App

