import React from 'react'
import Navbar from '../component/Navbar'
import Hero from '../component/Hero'
import Img from '../asets/image6.jpg'
import Footer from '../component/Footer'
import Aboutus from '../component/Aboutus'
const About = () => {
  return (
    <div>
       <Navbar/>
         <Hero
         cName="hero-mid" 
         image={Img}
        title="About"
        // text="Choose Your Favourite Destination" 
        // buttonText="Travel Plan"
        // url="/"
        btnClass="hide"/>
        <Aboutus/>
          <Footer/>
    </div>
  )
}

export default About
